<div class="web-container-cart" @showHide>
  <div class="container">
    <div class="section-top-header">
      <div class="flex flex-row justify-between items-center">
        <div class="col-md-6">
          <h1 class="section-button">cart summary</h1>
        </div>
        <div class="col-md-6">
          <div class="section-button-wrapper">
            <img (click)="goBack()" src="assets/images/cross-page-icon.svg" />
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-row justify-between" *ngIf="!isEmpty">
      <div class="col-span-12 col-lg-5 col-sm-12">
        <div class="cart-wrapper">
          <div class="add-cart-wrapper">
            <div class="add-cart-header">
              <h4>PACKAGES Added to cart</h4>
            </div>
            <div *ngFor="let i of items">
              <div class="add-cart-content">
                <i class="add-cart-icon">
                  <img src="{{ i.primary_image?.url }}" alt="primaryImage" />
                </i>
                <div class="add-text-wrapper">
                  <i class="cart-close">
                    <img
                      src="assets/images/cart-close-icon.svg"
                      alt="cartCloseIcon"
                      (click)="onRemoveItem(i)"
                    />
                  </i>
                  <h4>{{ i.name }}</h4>

                  <div class="addon-price" *ngIf="i.discountedPrice">
                    <p>
                      <span *ngIf="i.discountedPrice">
                        <!-- {{ i.discountedPrice | currency: '₹' }}/month -->
                        {{ priceManager.getPrice(i.discountedPrice) }}
                      </span>
                      <span *ngIf="!i.discountedPrice">
                        <!-- {{ 0 | currency: '₹' }}/month -->
                        {{ priceManager.getPrice(0) }}
                      </span>

                      <span> * </span>
                      <span>
                        <!-- {{ _getValidity() }} month -->
                        {{ _getValidity() }}
                        {{ priceManager.isAnnualSetUp ? 'year' : 'month' }}
                      </span>
                      <span> = </span>
                      <span *ngIf="i.discountedPrice">
                        <!-- {{ i.discountedPrice * _getValidity() | currency: '₹' }}
                        Rs -->
                        {{
                          priceManager.getPrice(
                            i.discountedPrice * _getValidity()
                          )
                        }}
                      </span>
                      <span *ngIf="!i.discountedPrice">
                        <!-- {{ 0 * _getValidity() | currency: '₹' }} Rs -->
                        {{ priceManager.getPrice(0) }}
                      </span>
                    </p>
                  </div>
                  <div class="addon-price" *ngIf="!i.discountedPrice">
                    <p>
                      <span *ngIf="i.price">
                        <!-- {{ i.price | currency: '₹' }}/month -->
                        {{ priceManager.getPrice(i.price) }}
                      </span>
                      <span *ngIf="!i.price">
                        <!-- {{ 0 | currency: '₹' }}/month -->
                        {{ priceManager.getPrice(0) }}
                      </span>
                      <span> * </span>
                      <span>
                        <!-- {{ _getValidity() }} month  -->
                        {{ _getValidity() }}
                        {{ priceManager.isAnnualSetUp ? 'year' : 'month' }}
                      </span>
                      <span> = </span>
                      <span *ngIf="i.price">
                        <!-- {{ i.price * _getValidity() | currency: '₹' }} Rs -->
                        {{ priceManager.getPrice(i.price * _getValidity()) }}
                      </span>
                      <span *ngIf="!i.price">
                        <!-- {{ 0 * _getValidity() | currency: '₹' }} Rs -->
                        {{ priceManager.getPrice(0) }}
                      </span>
                    </p>
                  </div>

                  <div
                    class="view-addons"
                    *ngIf="i.included_features && i.included_features.length"
                    (click)="onViewAddonClick(i.included_features, i._kid)"
                  >
                    View included add-ons
                    <i>
                      <img
                        *ngIf="!(i._kid == onViewAddons)"
                        src="assets/images/addon-arrow.svg"
                      />
                      <img
                        *ngIf="i._kid == onViewAddons"
                        class="rotate"
                        src="assets/images/addon-arrow.svg"
                      />
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="add-cart-wrapper" *ngIf="onViewAddons != null" @showHide>
            <div class="add-cart-header">
              <h4>ADD-ONS</h4>
            </div>

            <div class="addon-content" *ngFor="let addOns of includedAddOns">
              <i class="addon-icon">
                <img
                  src="{{ addOns.addOnDetail.primary_image.url }}"
                  alt="primaryImageUrl"
                />
              </i>
              <div class="addon-text-wrapper">
                <h4>{{ addOns.addOnDetail.name }}</h4>
                <div class="addon-price">
                  <p>
                    <span>
                      <!-- {{ addOns.addOnDetail.price | currency: '₹' }} /month -->
                      {{ priceManager.getPrice(addOns.addOnDetail.price) }}
                    </span>

                    <span
                      class="price-grey"
                      *ngIf="
                        addOns.addOnDetail.discount_percent &&
                        !(addOns.addOnDetail.discount_percent == 0)
                      "
                    >
                      {{
                        priceManager.getPrice(
                          addOns.addOnDetail.price +
                            (addOns.addOnDetail.discount_percent *
                              addOns.addOnDetail.price) /
                              100
                        )
                      }}
                      <!--

                      {{
                        addOns.addOnDetail.price +
                          (addOns.addOnDetail.discount_percent *
                            addOns.addOnDetail.price) /
                            100 | currency: '₹'
                      }}/month -->
                    </span>
                  </p>
                  <b
                    *ngIf="
                      addOns.addOnDetail.discount_percent &&
                      !(addOns.addOnDetail.discount_percent == 0)
                    "
                    >- {{ addOns.addOnDetail.discount_percent }} %</b
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-7 col-sm-12">
        <div class="subscription-wrapper">
          <div class="subscription-cart">
            <div class="add-cart-header">
              <h4><img src="assets/images/calender-icon.svg" /> Validity</h4>
              <p>Increase the validity duration to avoid frequent renewals</p>
            </div>
            <div class="validiy-bx">
              <div class="calender-bx">
                {{ _getValidity() }}
                {{ priceManager.isAnnualSetUp ? 'year' : 'month' }}
              </div>

              <div class="control-bx">
                <i
                  class="control-icon"
                  (click)="onCalculateValidity('-')"
                  [ngClass]="
                    validityNumber != minValidityNuber
                      ? ''
                      : 'disableStateButton'
                  "
                >
                  -
                </i>
                <p>
                  {{ _getValidity() }}
                </p>
                <i class="control-icon" (click)="onCalculateValidity('+')">
                  +
                </i>
              </div>
            </div>

            <div class="coupon-bx">
              <div class="discount-coupon-bx" (click)="onDiscountClick()">
                <h4>
                  <img src="assets/images/coupon-icon.svg" />
                  Discount Coupon
                </h4>
                <p>Apply if you’ve got any valid discount coupon.</p>
                <p
                  class="success-msg"
                  [ngClass]="isApplied ? 'isApplied' : 'none'"
                >
                  <span *ngIf="couponCodeRes && couponCodeRes.message">
                    {{ couponCodeRes.message }}
                  </span>
                  <span *ngIf="!couponCodeRes || !couponCodeRes.message">
                    Coupon applied successfully.
                  </span>

                  <!-- Coupon applied successfully. -->
                </p>

                <p
                  class="error-msg"
                  *ngIf="couponCodeRes"
                  [ngClass]="isErrorCoupon ? 'isApplied' : 'none'"
                >
                  {{ couponCodeRes.message }}
                </p>

                <i class="coupon-dropdown" (click)="animateClick()">
                  <img *ngIf="onView" src="assets/images/accordion-arrow.svg" />
                  <img
                    *ngIf="!onView"
                    class="icon-rotate"
                    src="assets/images/accordion-arrow.svg"
                  />
                </i>
              </div>
              <div class="coupon-child-bx" *ngIf="onView" @showHide>
                <form>
                  <div
                    class="coupon-code-bx"
                    [ngClass]="
                      'coupon' === isActiveLabel ? 'coupon-focus' : 'none'
                    "
                    [ngClass]="
                      'couponImplement' === isActiveLabel
                        ? 'coupon-edit-bx'
                        : 'none'
                    "
                  >
                    <input
                      type="text"
                      placeholder="Type the coupon code"
                      name="coupon"
                      [(ngModel)]="couponCode"
                      (focus)="isActivelabel('coupon')"
                      (focusout)="isActivelabel('none')"
                      [readonly]="isApplied"
                      [ngClass]="isApplied ? 'displayNone' : 'none'"
                    />
                    <button
                      type="submit"
                      class=""
                      (click)="onApplyCoupon()"
                      [ngClass]="
                        'coupon' === isActiveLabel ? 'btn-yellow' : 'none'
                      "
                      [ngClass]="isApplied ? 'displayNone' : 'none'"
                    >
                      Apply
                    </button>

                    <div
                      class="discount-wrapper"
                      [ngClass]="isApplied ? 'isApplied' : 'none'"
                      *ngIf="couponService.couponName"
                    >
                      {{ couponService.couponName }}
                    </div>
                    <i
                      class="edit-coupon"
                      [ngClass]="isApplied ? 'isApplied' : 'none'"
                    >
                      <img
                        (click)="onEditCoupon()"
                        src="assets/images/coupon-edit.svg"
                      />
                    </i>
                  </div>
                </form>
              </div>
            </div>

            <!-- GSTIN BOX -->
            <!-- <div class="coupon-bx">
              <div
                class="discount-coupon-bx"
                (click)="onFillInGstClick('GSTIN')"
              >
                <h4>
                  <img src="assets/images/gst-icon.svg" />
                  GSTIN (optional)
                </h4>
                <p>
                  Get an Invoice with your GSTIN to help you claim GST input tax
                  credit.
                </p>
                <i class="coupon-dropdown">
                  <img
                    *ngIf="onViewGst"
                    src="assets/images/accordion-arrow.svg"
                  />
                  <img
                    *ngIf="!onViewGst"
                    class="icon-rotate"
                    src="assets/images/accordion-arrow.svg"
                  />
                </i>
              </div>
              <div class="coupon-child-bx" *ngIf="onViewGst" @showHide>

                <div
                  class="coupon-code-bx"
                  [ngClass]="'gst' === isActiveLabel ? 'coupon-focus' : 'none'"
                >
                  <input
                    type="text"
                    (focus)="isActivelabel('gst')"
                    (focusout)="isActivelabel('none')"
                    [(ngModel)]="gstInNumber"
                    name="gstInNumber"
                    placeholder="Type your GSTIN"
                  />

                  <button
                    type="submit"
                    [ngClass]="'gst' === isActiveLabel ? 'btn-yellow' : 'none'"
                    (click)="onSaveGSTNumber('GSTIN')"
                    class=""
                  >
                    Save
                  </button>

                  <div class="edit-gst" style="display: none">EDIT</div>
                </div>

              </div>
            </div> -->
            <!-- GSTIN BOX END -->

            <!-- Email BOX -->
            <!-- <div class="coupon-bx">
              <div
                class="discount-coupon-bx"
                (click)="onFillInGstClick('Email')"
              >
                <h4>
                  <img src="assets/images/gst-icon.svg" />
                  EMAIL ID
                </h4>
                <p>
                  Enter your email address to recieve the details about your
                  order and future updates.
                </p>
                <i class="coupon-dropdown">
                  <img
                    *ngIf="onViewEmail"
                    src="assets/images/accordion-arrow.svg"
                  />
                  <img
                    *ngIf="!onViewEmail"
                    class="icon-rotate"
                    src="assets/images/accordion-arrow.svg"
                  />
                </i>
              </div>
              <div class="coupon-child-bx" *ngIf="onViewEmail" @showHide>
                <form #emailForm="ngForm" (ngSubmit)="onSaveGSTNumber('Email')">
                  <div
                    class="coupon-code-bx"
                    [ngClass]="
                      'email' === isActiveLabel ? 'coupon-focus' : 'none'
                    "
                  >
                    <input
                      type="text"
                      (focus)="isActivelabel('email')"
                      (focusout)="isActivelabel('none')"
                      [(ngModel)]="email"
                      name="email"
                      placeholder="Enter your email address here"
                      pattern='^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
                      required
                    />

                    <button
                      type="submit"
                      [ngClass]="
                        'email' === isActiveLabel && emailForm.form.valid
                          ? 'btn-yellow'
                          : 'none'
                      "
                      [disabled]="!emailForm.form.valid"
                      class=""
                    >
                      Save
                    </button>

                    <div class="edit-gst" style="display: none">EDIT</div>
                  </div>
                </form>
              </div>
            </div> -->
            <!-- EMAIL BOX END -->

            <div class="amount-bx">
              <table>
                <tr>
                  <td>Cart amount</td>
                  <td *ngIf="cartService.totalAmountWithOutGST">
                    <!-- {{ totalAmountWithOutGST | currency: '₹' }} -->
                    {{
                      priceManager.getPrice(cartService.totalAmountWithOutGST)
                    }}
                  </td>
                  <td *ngIf="!cartService.totalAmountWithOutGST">
                    <!-- {{ 0 | currency: '₹' }} -->
                    {{ priceManager.getPrice(0) }}
                  </td>
                </tr>
                <tr>
                  <td>Coupon discount</td>
                  <td *ngIf="cartService.couponDiscountPrice">
                    <!-- {{ priceManager.getPrice(cartService.couponDiscountPrice) }} -->
                    {{ cartService.couponDiscountPrice | currency: '₹' }}
                  </td>
                  <td *ngIf="!cartService.couponDiscountPrice">
                    {{ 0 | currency: '₹' }}
                  </td>
                </tr>
                <tr>
                  <td>TAN (optional)</td>
                  <td class="tan-num" (click)="onEnterTan('')">
                    {{ _getTanNumber() }}
                  </td>
                </tr>
                <tr *ngIf="onViewTan" @showHide>
                  <td colspan="2" class="input-tan-bx">
                    <div class="coupon-code-bx coupon-focus">
                      <input
                        type="text"
                        [ngModel]="tanNumber"
                        #tansNumber
                        name="tanNumber"
                      />
                      <!-- <button
                        class="btn-yellow"
                        (click)="onEnterTan(tansNumber.value)"
                      >
                        Enter
                      </button> -->
                      <nf-raised-button
                        [style]="{ 'font-size': '12px', 'font-weight': '600' }"
                        className="uppercase"
                        (click)="onEnterTan(tansNumber.value)"
                      >
                        Enter
                      </nf-raised-button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>IGST (18%)</td>
                  <td *ngIf="cartService.gstAmount">
                    {{ cartService.gstAmount | currency: '₹' }}
                    <!-- {{ priceManager.getPrice(cartService.gstAmount) }} -->
                    <!-- {{ cartService.gstAmount | currency: "₹" }} -->
                  </td>
                  <td *ngIf="!cartService.gstAmount">
                    <!-- {{ 0 | currency: '₹' }} -->
                    {{ priceManager.getPrice(0) }}
                  </td>
                </tr>
                <tr>
                  <td>Order Total</td>
                  <td *ngIf="cartService.totalAmountWithGST">
                    {{ cartService.totalAmountWithGST | currency: '₹' }}
                    <!-- {{ priceManager.getPrice(cartService.totalAmountWithGST) }} -->
                  </td>
                  <td *ngIf="!cartService.totalAmountWithGST">
                    {{ 0 | currency: '₹' }}
                    <!-- {{ priceManager.getPrice(0) }} -->
                  </td>
                </tr>
              </table>
              <div class="total-ammount-bx">
                <div class="view-details">Total</div>
                <div class="total-price">
                  {{ cartService.totalAmountWithGST | currency: '₹' }}
                  <!-- {{ priceManager.getPrice(cartService.totalAmountWithGST) }} -->
                </div>
                <div
                  class="total-price"
                  *ngIf="!cartService.totalAmountWithGST"
                >
                  <!-- {{ cartService.totalAmountWithGST | currency: '₹' }} -->
                  {{ priceManager.getPrice(cartService.totalAmountWithGST) }}
                </div>
              </div>
            </div>
          </div>

          <div class="continue-btn-bx">
            <div *ngIf="errorMsg && errorMsg.length" class="error">
              {{ errorMsg }}
            </div>

            <div class="w-full flex justify-end gap-3 items-center">
              <!-- <a (click)="goBack()">Go Back</a> -->
              <nf-raised-button
                [style]="{
                  'font-weight': '700',
                  'font-size': '15px',
                  color: 'var(--primary-color)'
                }"
                className="transparent-btn uppercase"
                (click)="goBack()"
                >Go Back</nf-raised-button
              >
              <!-- <button
                type="button"
                class="btn-yellow"
                *ngIf="!isLoader"
                (click)="onOpenModel()"
              >
                Continue
              </button> -->

              <nf-raised-button
                [style]="{
                  'font-weight': '700',
                  'font-size': '15px',
                  padding: '7px 42px'
                }"
                *ngIf="!isLoader"
                (click)="onOpenModel()"
              >
                Continue
              </nf-raised-button>
            </div>
            <div class="btn-yellow loader" *ngIf="isLoader">
              <div class="full-loader"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-center" *ngIf="isEmpty">
      <div class="col-span-12">
        <section class="section-item-zero">
          <div class="cart-summary-msg">
            <!-- <img src="assets/images/icon-feels-light.svg" /> -->
            <svg
              width="305"
              height="278"
              viewBox="0 0 305 278"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M170.403 238.151L298.59 209.878C300.408 209.477 301.996 208.378 303.011 206.816C304.026 205.255 304.386 203.357 304.013 201.532C298.679 175.359 277.164 80.9257 236.265 35.4891C235.302 34.4265 234.039 33.6819 232.643 33.354C231.246 33.0262 229.784 33.1306 228.448 33.6534L148.34 64.7969C147.464 65.1371 146.664 65.6484 145.986 66.3008C145.309 66.9533 144.768 67.7339 144.396 68.5973C144.023 69.4606 143.826 70.3895 143.815 71.3299C143.805 72.2702 143.982 73.2032 144.336 74.0745C151.995 92.9058 171.576 150.711 161.836 230.351C161.697 231.478 161.83 232.621 162.224 233.685C162.619 234.749 163.262 235.703 164.102 236.468C164.941 237.232 165.951 237.783 167.048 238.076C168.144 238.369 169.295 238.395 170.403 238.151Z"
                fill="var(--primary-color)"
              />
              <path
                d="M213.797 60.2859C217.369 60.2859 220.266 57.3897 220.266 53.8171C220.266 50.2446 217.369 47.3484 213.797 47.3484C210.224 47.3484 207.328 50.2446 207.328 53.8171C207.328 57.3897 210.224 60.2859 213.797 60.2859Z"
                fill="white"
              />
              <path
                d="M175.716 76.271C179.289 76.271 182.185 73.3749 182.185 69.8023C182.185 66.2297 179.289 63.3335 175.716 63.3335C172.144 63.3335 169.248 66.2297 169.248 69.8023C169.248 73.3749 172.144 76.271 175.716 76.271Z"
                fill="white"
              />
              <path
                d="M213.797 53.8186C207.097 38.5607 197.85 24.5537 186.452 12.397C186.452 12.397 147.398 -34.1709 175.718 69.8022"
                stroke="#4E270E"
                stroke-width="4"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
              <path
                d="M222.15 174.665C220.936 174.664 219.722 174.599 218.515 174.468C211.866 173.762 205.583 171.077 200.477 166.76C195.507 162.561 191.862 157.01 189.983 150.781C188.479 145.774 188.165 140.484 189.067 135.334C189.969 130.184 192.062 125.316 195.179 121.117C198.295 116.919 202.35 113.507 207.018 111.154C211.687 108.8 216.841 107.57 222.07 107.561C223.285 107.562 224.5 107.627 225.708 107.758C233.648 108.612 241.023 112.272 246.506 118.078C251.99 123.884 255.222 131.456 255.621 139.432L247.156 134.332L239.393 138.243C238.759 134.472 236.912 131.011 234.131 128.385C231.351 125.759 227.79 124.112 223.989 123.694C223.358 123.626 222.723 123.592 222.089 123.592C217.606 123.597 213.296 125.319 210.044 128.405C206.793 131.491 204.849 135.706 204.611 140.182C204.373 144.659 205.86 149.056 208.765 152.469C211.671 155.883 215.774 158.052 220.231 158.532C220.863 158.6 221.498 158.634 222.133 158.634C225.655 158.618 229.091 157.546 231.998 155.557C234.904 153.568 237.148 150.753 238.439 147.476L247.675 142.504L255.105 147.194C253.688 154.903 249.616 161.871 243.596 166.889C237.575 171.908 229.988 174.659 222.15 174.665Z"
                fill="white"
              />
              <path
                d="M106.566 153.793C116.001 153.793 124.321 146.357 124.321 136.922C124.321 135.128 123.967 133.351 123.281 131.693C122.594 130.035 121.587 128.528 120.318 127.259C119.049 125.99 117.542 124.984 115.884 124.297C114.226 123.61 112.449 123.257 110.654 123.257C107.758 123.259 104.98 124.411 102.931 126.459C100.883 128.508 99.7314 131.285 99.7295 134.182C99.7295 136.502 100.651 138.727 102.291 140.368C103.932 142.008 106.157 142.93 108.477 142.93"
                stroke="#9B9B9B"
                stroke-width="2"
                stroke-miterlimit="10"
              />
              <path
                d="M107.202 153.793C88.1159 153.505 38.3122 153.241 0.84375 153.793"
                stroke="#9B9B9B"
                stroke-width="2"
                stroke-miterlimit="10"
              />
              <path
                d="M69.4584 144.659C74.0104 144.723 78.4034 142.986 81.6812 139.827C84.959 136.667 86.8562 132.341 86.96 127.79C86.96 125.995 86.6065 124.218 85.9197 122.56C85.2329 120.902 84.2262 119.396 82.9571 118.127C81.6881 116.858 80.1815 115.851 78.5234 115.165C76.8654 114.478 75.0883 114.125 73.2937 114.125C70.3953 114.127 67.6162 115.28 65.5676 117.33C63.5189 119.38 62.3683 122.16 62.3687 125.059C62.369 127.378 63.2906 129.602 64.9307 131.242C66.5708 132.882 68.7951 133.803 71.1144 133.803"
                stroke="#9B9B9B"
                stroke-width="2"
                stroke-miterlimit="10"
              />
              <path
                d="M69.8368 144.658C56.2136 144.242 23.9474 144.242 10.4019 144.658"
                stroke="#9B9B9B"
                stroke-width="2"
                stroke-miterlimit="10"
              />
              <path
                d="M67.3468 162.924C74.2717 163.023 80.8793 165.844 85.7413 170.776C90.6034 175.708 93.3292 182.355 93.3297 189.281C93.33 192.002 92.7944 194.696 91.7535 197.21C90.7125 199.724 89.1865 202.008 87.2627 203.932C85.3389 205.856 83.0549 207.382 80.5412 208.424C78.0275 209.465 75.3332 210.001 72.6124 210.001C70.4349 210.001 68.2786 209.573 66.2668 208.74C64.2549 207.907 62.4269 206.686 60.8872 205.146C59.3475 203.606 58.1262 201.778 57.2932 199.766C56.4601 197.754 56.0317 195.598 56.0322 193.421C56.0318 191.679 56.3745 189.955 57.0406 188.346C57.7067 186.737 58.6832 185.275 59.9143 184.043C61.1455 182.812 62.6071 181.835 64.2159 181.168C65.8246 180.502 67.5489 180.158 69.2903 180.158"
                stroke="#9B9B9B"
                stroke-width="2"
                stroke-miterlimit="10"
              />
              <path
                d="M67.344 162.924C57.6394 162.51 33.9192 162.51 24.2275 162.924"
                stroke="#9B9B9B"
                stroke-width="2"
                stroke-miterlimit="10"
              />
              <path
                d="M107.951 165.068C114.467 165.068 120.851 171.235 120.851 177.754C120.851 180.259 119.856 182.66 118.085 184.431C116.314 186.202 113.912 187.197 111.408 187.197C109.404 187.197 107.482 186.401 106.065 184.984C104.649 183.567 103.853 181.646 103.853 179.642C103.855 178.04 104.493 176.504 105.626 175.372C106.76 174.24 108.297 173.604 109.899 173.604"
                stroke="#9B9B9B"
                stroke-width="2"
                stroke-miterlimit="10"
              />
              <path
                d="M108.786 164.942H90.9097"
                stroke="#9B9B9B"
                stroke-width="2"
                stroke-miterlimit="10"
              />
              <path
                d="M223.063 278C253.629 278 278.407 276.069 278.407 273.688C278.407 271.306 253.629 269.375 223.063 269.375C192.498 269.375 167.719 271.306 167.719 273.688C167.719 276.069 192.498 278 223.063 278Z"
                fill="#DFDFDF"
              />
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="223.986"
                  y1="119.125"
                  x2="223.986"
                  y2="238.317"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#012FB6" />
                  <stop offset="1" stop-color="#F5A623" />
                </linearGradient>
              </defs>
            </svg>

            <h4>No item in the cart!</h4>
            <p>Add here to buy add-ons and see the difference.</p>
            <!-- <button
              type="button"
              (click)="onOpenViewModal()"
              class="btn-yellow"
            >
              View Recommended Add-ons
            </button> -->
            <div class="w-full flex justify-center">
              <nf-raised-button
                [style]="{ 'font-weight': '700' }"
                className="uppercase"
                (click)="onOpenViewModal()"
              >
                View Recommended Add-ons
              </nf-raised-button>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
