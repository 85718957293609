import { NfClient } from './client.enum';
import { NfClientConfig } from './client.type';

export const NF_CLIENT_CONFIG: NfClientConfig[] = [
  {
    client: NfClient.Boost,
    stageClientId:
      'A91B82DE3E93446A8141A52F288F69EFA1B09B1D13BB4E55BE743AB547B3489E',
    prodClientId:
      'A91B82DE3E93446A8141A52F288F69EFA1B09B1D13BB4E55BE743AB547B3489E',
    clientId: '',
    email: ['ria@nowfloats.com'],
    phone: ['1800-889-9444'],
    theming: {
      colors: {
        primaryColor: '#012FB6',
        accentColor: '#012FB6',
        warnColor: '#EB5757',
        colorWhite: '#ffffff',
        colorSecondary: '#4F4F4F',
        primaryTextColor: '#4A4A4A',
        tertiaryTextColor: '#00000099',
        tertiary1TextColor: '#00000061',
        primaryGrey: '#f8f8f8',
        secondaryGrey: '#e3e3e3',
        tertiaryGrey: '#9b9b9b',
      },
      typography: {
        fontFamily: 'Open Sans',
      },
    },
  },
  {
    client: NfClient.JioOnline,
    stageClientId:
      'D01489A9F554586895577B2569EFA9B1D13BB4423A473E79AB54DD424124BE70',
    prodClientId:
      '2D5C6BB4F46457422DA36B4977BD12E37A92EEB13BB4423A548387BA54DCEBD5',
    clientId: '',
    email: ['businesscare@jio.com'],
    phone: ['1800-889-9444'],
    theming: {
      colors: {
        primaryColor: '#0400A6',
        accentColor: '#EC038C',
        warnColor: '#b14040',
        colorWhite: '#ffffff',
        colorSecondary: '#4F4F4F',
        primaryTextColor: '#4A4A4A',
        tertiaryTextColor: '#00000099',
        tertiary1TextColor: '#00000061',
        primaryGrey: '#f8f8f8',
        secondaryGrey: '#e3e3e3',
        tertiaryGrey: '#9b9b9b',
      },
      typography: {
        fontFamily: 'JioType',
      },
    },
  },
];
