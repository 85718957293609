import { NfAppExpCode, NfApplication } from '../menu.enum';
import { NfMenuConfig } from '../menu.type';

const menuName = 'ADD-ONS MARKETPLACE';
const titleName = 'Marketplace';
export const NF_ADDON_MARKETPLACE_MENU_CONFIG: NfMenuConfig[] = [
  {
    application: NfApplication.AddonMarketplace,
    appExpCode: NfAppExpCode.DOC,
    menu: {
      name: menuName,
      title: titleName,
      meta: {
        boostTitle: 'Ardhim: Marketplace',
        jioTitle: 'JioOnline: Marketplace'
      },
      // items: [
      //   // {
      //   //   name: 'Marketplace',
      //   //   url: '/addon-marketplace/marketplace',
      //   // },
      //   {
      //     name: 'Installed Addons',
      //     url: '/addon-marketplace/installed-addons',
      //       // meta: {
      //     //   boostTitle: 'Ardhim: Installed Add-ons',
      //     //   jioTitle: 'JioOnline: Installed Add-ons'
      //     // }
      //   },
      // ],
    },
  },
  {
    application: NfApplication.AddonMarketplace,
    appExpCode: NfAppExpCode.HOS,
    menu: {
      name: menuName,
      title: titleName,
      meta: {
        boostTitle: 'Ardhim: Marketplace',
        jioTitle: 'JioOnline: Marketplace'
      },
      // items: [
      //   // {
      //   //   name: 'Marketplace',
      //   //   url: '/addon-marketplace/marketplace',
      //   // },
      //   {
      //     name: 'Installed Addons',
      //     url: '/addon-marketplace/installed-addons',
      //        // meta: {
      //     //   boostTitle: 'Ardhim: Installed Add-ons',
      //     //   jioTitle: 'JioOnline: Installed Add-ons'
      //     // }
      //   },
      // ],
    },
  },
  {
    application: NfApplication.AddonMarketplace,
    appExpCode: NfAppExpCode.HOT,
    menu: {
      name: menuName,
      title: titleName,
      meta: {
        boostTitle: 'Ardhim: Marketplace',
        jioTitle: 'JioOnline: Marketplace'
      },
      // items: [
      //   // {
      //   //   name: 'Marketplace',
      //   //   url: '/addon-marketplace/marketplace',
      //   // },
      //   {
      //     name: 'Installed Addons',
      //     url: '/addon-marketplace/installed-addons',
      //        // meta: {
      //     //   boostTitle: 'Ardhim: Installed Add-ons',
      //     //   jioTitle: 'JioOnline: Installed Add-ons'
      //     // }
      //   },
      // ],
    },
  },
  {
    application: NfApplication.AddonMarketplace,
    appExpCode: NfAppExpCode.CAF,
    menu: {
      name: menuName,
      title: titleName,
      meta: {
        boostTitle: 'Ardhim: Marketplace',
        jioTitle: 'JioOnline: Marketplace'
      },
      // items: [
      //   // {
      //   //   name: 'Marketplace',
      //   //   url: '/addon-marketplace/marketplace',
      //   // },
      //   {
      //     name: 'Installed Addons',
      //     url: '/addon-marketplace/installed-addons',
      //        // meta: {
      //     //   boostTitle: 'Ardhim: Installed Add-ons',
      //     //   jioTitle: 'JioOnline: Installed Add-ons'
      //     // }
      //   },
      // ],
    },
  },
  {
    application: NfApplication.AddonMarketplace,
    appExpCode: NfAppExpCode.EDU,
    menu: {
      name: menuName,
      title: titleName,
      meta: {
        boostTitle: 'Ardhim: Marketplace',
        jioTitle: 'JioOnline: Marketplace'
      },
      // items: [
      //   // {
      //   //   name: 'Marketplace',
      //   //   url: '/addon-marketplace/marketplace',
      //   // },
      //   {
      //     name: 'Installed Addons',
      //     url: '/addon-marketplace/installed-addons',
      //        // meta: {
      //     //   boostTitle: 'Ardhim: Installed Add-ons',
      //     //   jioTitle: 'JioOnline: Installed Add-ons'
      //     // }
      //   },
      // ],
    },
  },
  {
    application: NfApplication.AddonMarketplace,
    appExpCode: NfAppExpCode.SPA,
    menu: {
      name: menuName,
      title: titleName,
      meta: {
        boostTitle: 'Ardhim: Marketplace',
        jioTitle: 'JioOnline: Marketplace'
      },
      // items: [
      //   // {
      //   //   name: 'Marketplace',
      //   //   url: '/addon-marketplace/marketplace',
      //   // },
      //   {
      //     name: 'Installed Addons',
      //     url: '/addon-marketplace/installed-addons',
      //        // meta: {
      //     //   boostTitle: 'Ardhim: Installed Add-ons',
      //     //   jioTitle: 'JioOnline: Installed Add-ons'
      //     // }
      //   },
      // ],
    },
  },
  {
    application: NfApplication.AddonMarketplace,
    appExpCode: NfAppExpCode.SAL,
    menu: {
      name: menuName,
      title: titleName,
      meta: {
        boostTitle: 'Ardhim: Marketplace',
        jioTitle: 'JioOnline: Marketplace'
      },
      // items: [
      //   // {
      //   //   name: 'Marketplace',
      //   //   url: '/addon-marketplace/marketplace',
      //   // },
      //   {
      //     name: 'Installed Addons',
      //     url: '/addon-marketplace/installed-addons',
      //        // meta: {
      //     //   boostTitle: 'Ardhim: Installed Add-ons',
      //     //   jioTitle: 'JioOnline: Installed Add-ons'
      //     // }
      //   },
      // ],
    },
  },
  {
    application: NfApplication.AddonMarketplace,
    appExpCode: NfAppExpCode.RTL,
    menu: {
      name: menuName,
      title: titleName,
      meta: {
        boostTitle: 'Ardhim: Marketplace',
        jioTitle: 'JioOnline: Marketplace'
      },
      // items: [
      //   // {
      //   //   name: 'Marketplace',
      //   //   url: '/addon-marketplace/marketplace',
      //   // },
      //   {
      //     name: 'Installed Addons',
      //     url: '/addon-marketplace/installed-addons',
      //        // meta: {
      //     //   boostTitle: 'Ardhim: Installed Add-ons',
      //     //   jioTitle: 'JioOnline: Installed Add-ons'
      //     // }
      //   },
      // ],
    },
  },
  {
    application: NfApplication.AddonMarketplace,
    appExpCode: NfAppExpCode.SVC,
    menu: {
      name: menuName,
      title: titleName,
      meta: {
        boostTitle: 'Ardhim: Marketplace',
        jioTitle: 'JioOnline: Marketplace'
      },
      // items: [
      //   // {
      //   //   name: 'Marketplace',
      //   //   url: '/addon-marketplace/marketplace',
      //   // },
      //   {
      //     name: 'Installed Addons',
      //     url: '/addon-marketplace/installed-addons',
      //        // meta: {
      //     //   boostTitle: 'Ardhim: Installed Add-ons',
      //     //   jioTitle: 'JioOnline: Installed Add-ons'
      //     // }
      //   },
      // ],
    },
  },
  {
    application: NfApplication.AddonMarketplace,
    appExpCode: NfAppExpCode.MFG,
    menu: {
      name: menuName,
      // items: [
      //   // {
      //   //   name: 'Marketplace',
      //   //   url: '/addon-marketplace/marketplace',
      //   // },
      //   {
      //     name: 'Installed Addons',
      //     url: '/addon-marketplace/installed-addons',
      //        // meta: {
      //     //   boostTitle: 'Ardhim: Installed Add-ons',
      //     //   jioTitle: 'JioOnline: Installed Add-ons'
      //     // }
      //   },
      // ],
    },
  },
];
