import { ImageGallery } from './../../../manage-content-app/src/app/image-gallery/image-gallery.model';
import { UpdatePostedComponent } from './../../../manage-content-app/src/app/latest-update/update-posted/update-posted.component';
import { AddUpdateComponent } from './../../../manage-content-app/src/app/latest-update/add-update/add-update.component';
import { Component, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import {
  NfClientService,
  NfMenuService,
  NfThemeService,
  NfAppExpCode,
  NfApplication,
  NfMenuConfig,
  NfClient,
} from '@nf/core';
import { CanAutoUnsubscribe, NfApiConfig, NfApiService } from '@nf/common';
import { environment, LocalStorageKeys } from '../environments/environment';
import { LoginService } from './login/login.service';
import { DomainAndEmailComponent } from 'projects/setting-app/src/app/domain-and-email/domain-and-email.component';
import { RepublishWebsiteModalComponent } from './modal/republish-website-modal/republish-website-modal.component';
import { ApiRequestGenerator } from 'projects/common-lib/src/lib/Utils/Api-requestgenerator';
import { Notifications } from './login/model/NotificationDataModel';
import { CheckUtil } from 'projects/common-lib/src/lib/Utils/check-utils';
import { getTestimonialPermission } from './config/option-list-config';
import { NeedHelpModalComponent } from './login/need-help-modal/need-help-modal.component';
import { Subscription } from 'rxjs';
import { VideoModalComponent } from 'projects/dashboard-app/src/app/modals/video-modal/video-modal.component';
import { MerchantService } from 'projects/dashboard-app/src/app/services/merchant.service';
import { MlcService } from './services/mlc.service';
import { AllStoreComponent } from './modal/all-store/all-store.component';

@Component({
  selector: 'main-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
@CanAutoUnsubscribe
export class AppComponent implements OnInit {
  public activeTab!: string;
  public activeSubTab!: string;
  public currentURL = '';
  public apiConfig: NfApiConfig = environment.apiConfig;
  public authToken: any = {};
  public authData: any = {};
  public isOptionList: boolean = false;
  public isMLC: boolean = false;

  public notificationCount: any = -1;
  public isNotificationCount: boolean = false;
  public showNotificationList: boolean = false;
  public notificationOffset: number = 0;
  public notficationsList: Notifications[] = [];
  public notficationsListRead: Notifications[] = [];
  public notificationLoaded: boolean = false;

  public isTestimonial: boolean = false;
  public manageUserMenu!: NfMenuConfig;
  public manageContentMenu!: NfMenuConfig;
  public manageReports!: NfMenuConfig;
  public manageMarketPlace!: NfMenuConfig;
  public manageSettings!: NfMenuConfig;
  public appCode!: string;
  public isHumbergerMenu: boolean = false;
  public menuHeader: string = '';
  public logoUrl?: string;
  public city: string = '';
  public drs_total: number = 0;

  //subscription variables
  public loginSub?: Subscription;
  public imageGallerySub?: Subscription;
  public notificationCountSub?: Subscription;
  public notificationReadSub?: Subscription;
  public notificationSub?: Subscription;
  public dialogSub?: Subscription;

  //client flags
  public boostActive: boolean = false;
  public jioActive: boolean = false;
  public fullYear: number = new Date().getFullYear();

  constructor(
    private themeService: NfThemeService,
    public clientService: NfClientService,
    public loginService: LoginService,
    private router: Router,
    public dialog: MatDialog,
    public menuService: NfMenuService,
    private apiService: NfApiService,
    private merchantService: MerchantService,
    public mlcService: MlcService
  ) {
    this.loginService.partnerValidationValue = JSON.parse(
      localStorage.getItem('partnerValidation')!
    );
    this.themeService.setTheme();
    this.menuService.setTitle('');
    this.afterLogIn();

    //handling mlc service
    this.loginSub = this.mlcService.isMlcLoaded$.subscribe((res: boolean) => {
      if (res) {
        this.isMLC = this.mlcService.isMLCStore ? false : true;
        this.menuService.setMenuConfig();
        this.setMenuConfig(this.mlcService.appExpCode);
        this.menuService.setTitle(this.mlcService.appExpCode);
        this.authToken = this.mlcService.authToken;
        this.getImageGallery();
        this.getAllNotificationsCount();
      }
    });

    //handling client flags
    if (this.clientService.currentClientConfig().client == NfClient.Boost) {
      this.boostActive = true;
      document
        .getElementById('faviconTag')
        ?.setAttribute(
          'href',
          'https://www.ardhim.biz/static/ardhim-favicon.svg'
        );
    } else if (
      this.clientService.currentClientConfig().client == NfClient.JioOnline
    ) {
      this.jioActive = true;
      document
        .getElementById('faviconTag')
        ?.setAttribute('href', 'assets/jio-online-images/jio-logo.svg');
    }
  }

  ngOnInit() {
    this.loginSub = this.loginService.changeHeaderLogo$.subscribe((res) => {
      if (res) this.getImageGallery();
    });

    this.merchantService.getMerchantData.subscribe((data) => {
      if (data && data.length && data[0].drs_total >= 0) {
        this.drs_total = data[0].drs_total;
      } else {
        let drs: any = 0;
        if (this.mlcService.isMLCStore)
          drs = sessionStorage.getItem(LocalStorageKeys.drs_total);
        else drs = localStorage.getItem(LocalStorageKeys.drs_total);
        if (drs && drs > 0) {
          this.drs_total = drs;
        }
      }
      this.drs_total = Math.floor(this.drs_total);
    });
  }

  afterLogIn() {
    this.loginSub = this.loginService.isLoggedIn$.subscribe((res) => {
      if (res) {
        const authData = this.loginService.getLocalStorage(
          this.apiConfig.authData!
        );
        const authToken = this.loginService.getLocalStorage(
          LocalStorageKeys.auth_token
        );
        const appCode = this.loginService.getLocalStorage(
          LocalStorageKeys.app_exp_code
        )!;

        const user = JSON.parse(authData!);
        if (user.isEnterprise) {
          this.menuService.setMenuConfig(true);
          this.isMLC = true;
        } else {
          this.menuService.setMenuConfig();
          this.isMLC = false;
        }

        if (authToken && authToken.length) {
          this.authToken = JSON.parse(authToken);
          if (appCode) {
            this.setMenuConfig(appCode);
            this.appCode = appCode;
          }
        }
        this.isTestimonial = getTestimonialPermission(this.appCode);
        if (authData && authData.length) {
          this.authData = JSON.parse(authData);
        }
        this.getAllNotificationsCount();
        this.getImageGallery();

        this.detectRoute();
      }
    });
  }

  detectRoute() {
    //observing the route change and setting the title
    this.loginSub = this.router.events.subscribe((res) => {
      if (res instanceof NavigationEnd) {
        const appExp = localStorage.getItem(LocalStorageKeys.app_exp_code);
        if (this.mlcService.isMLCStore && this.mlcService.appExpCode) {
          this.menuService.setTitle(this.mlcService.appExpCode);
        } else {
          this.menuService.setTitle(appExp);
        }
      }
    });
  }

  getImageGallery() {
    const imageGallery = {
      clientId: this.clientService.defaultClientConfig().clientId,
      floatingPointId: this.authToken.FloatingPointId,
    };
    this.imageGallerySub = this.apiService
      .post<any>(imageGallery, {
        apiPath: '/internal/v1/GetFloatingPointDetailsById',
      })
      .subscribe(
        (imageData: ImageGallery) => {
          this.logoUrl = imageData.LogoUrl;
          this.city = imageData.City || '';
        },
        (error) => {}
      );
  }

  onHamburgerClick() {
    this.isHumbergerMenu = !this.isHumbergerMenu;
    this.menuHeader = '';
  }
  getAllNotificationsCount() {
    this.notificationCountSub = this.apiService
      .get(
        ApiRequestGenerator.getAllNotificationCount(
          this.clientService.defaultClientConfig().clientId,
          this.authToken.FloatingPointId
        )
      )
      .subscribe((res) => {
        this.notificationCount = res;

        if (this.notificationCount > 0) {
          this.isNotificationCount = true;
        }
      });
  }

  onClickNotificationIcon() {
    this.showNotificationList = !this.showNotificationList;
    if (CheckUtil.isEmptyList(this.notficationsList)) {
      this.getAllNotifications();
    } else {
      let notIds = [];
      for (let notif of this.notficationsList) {
        if (notif.isRead == false) {
          if (notif.buttonText == '') {
            notIds.push(notif._id);
          }
        }
      }
      if (!CheckUtil.isEmptyList(notIds)) {
        let c = 0;
        for (let id of notIds) {
          c++;
          this.markNotificationAsRead(id);
        }
        if (c == notIds.length) {
          this.getAllNotificationsCount();
        }
      }
    }
  }
  markNotificationAsRead(id: string) {
    this.notificationReadSub = this.apiService
      .post(
        null,
        ApiRequestGenerator.markNotificationAsRead(
          this.clientService.defaultClientConfig().clientId,
          id
        )
      )
      .subscribe((res) => {});
  }
  getAllNotifications() {
    this.notificationSub = this.apiService
      .get<any[]>(
        ApiRequestGenerator.getAllNotificationList(
          this.clientService.defaultClientConfig().clientId,
          this.authToken.FloatingPointId,
          this.notificationOffset
        )
      )
      .subscribe((res) => {
        if (res) {
          for (let item of res) {
            if (!item.isRead)
              this.notficationsList.push(new Notifications(item));
            else this.notficationsListRead.push(new Notifications(item));
          }
          if (res.length < 100) {
            this.notificationLoaded = true;
            if (this.notficationsListRead.length > 10) {
              let temp = this.notficationsList.length;
              for (let i = 0; i < 10 - temp; i++) {
                this.notficationsList.push(this.notficationsListRead[i]);
              }
            } else {
              for (let item of this.notficationsListRead) {
                this.notficationsList.push(item);
              }
            }
          } else {
            this.notificationOffset = this.notificationOffset + res.length;
            this.getAllNotifications();
          }
        }
      });
  }

  onListIconClick() {
    this.isOptionList = !this.isOptionList;
  }
  onSetTitle(module: number, menu: string) {
    if (menu.length) {
      this.getMenuHeader(menu);
    }
    // setTimeout(() => {
    //   this.menuService.setTitleOfFavicon(
    //     module,
    //     this.getAppExpCode(this.appCode?.toLowerCase())
    //   )!;
    // }, 300);
  }

  onRedirect(number: number, data: string) {
    this.onSetTitle(number, data);
    return data;
  }

  getMenuHeader(menu: string) {
    switch (menu) {
      case 'DASHBOARD':
        this.menuHeader = 'DASHBOARD';
        this.chechIsExistMenuBar('MANAGE_CUSTOMER');
        break;
      case 'MANAGE_CONTENT':
        this.chechIsExistMenuBar('MANAGE_CONTENT');
        break;
      case 'REPORTS':
        this.chechIsExistMenuBar('REPORTS');
        break;
      case 'MARKETPLACE':
        this.chechIsExistMenuBar('MARKETPLACE');
        break;
      case 'SETTING':
        this.chechIsExistMenuBar('SETTING');
        break;
      default:
        break;
    }
  }

  chechIsExistMenuBar(title: string) {
    if (this.menuHeader == title) {
      this.menuHeader = '';
    } else {
      this.menuHeader = title;
    }
  }

  setMenuConfig(expCode: string) {
    this.manageUserMenu = this.menuService.getMenuConfig(
      NfApplication.ManageUser,
      this.getAppExpCode(expCode.toLowerCase())
    )!;

    this.manageContentMenu = this.menuService.getMenuConfig(
      NfApplication.ManageContent,
      this.getAppExpCode(expCode.toLowerCase())
    )!;

    this.manageReports = this.menuService.getMenuConfig(
      NfApplication.ManageReport,
      this.getAppExpCode(expCode.toLowerCase())
    )!;

    this.manageMarketPlace = this.menuService.getMenuConfig(
      NfApplication.AddonMarketplace,
      this.getAppExpCode(expCode.toLowerCase())
    )!;

    this.manageSettings = this.menuService.getMenuConfig(
      NfApplication.Setting,
      this.getAppExpCode(expCode.toLowerCase())
    )!;
  }

  getAppExpCode(code: string): NfAppExpCode {
    if (code === 'doc') return NfAppExpCode.DOC;
    else if (code === 'hos') return NfAppExpCode.HOS;
    else if (code === 'hot') return NfAppExpCode.HOT;
    else if (code === 'caf') return NfAppExpCode.CAF;
    else if (code === 'edu') return NfAppExpCode.EDU;
    else if (code === 'spa') return NfAppExpCode.SPA;
    else if (code === 'sal') return NfAppExpCode.SAL;
    else if (code === 'mfg') return NfAppExpCode.MFG;
    else if (code === 'rtl') return NfAppExpCode.RTL;
    else return NfAppExpCode.SVC;
  }

  onLogout() {
    this.loginService.clearLocalStorage();
    this.loginService.setLogin(false);
    this.router.navigateByUrl('/login');
    this.menuService.setTitleOfFavicon(NfApplication.Login, '');
  }

  orderListRouting(nav: number) {
    switch (nav) {
      case 1:
        this.postAnUpdate();
        break;
      case 2:
        this.routeChange('/manage-content/all-service-catalogue');
        this.isOptionList = false;
        break;
      case 3:
        this.routeChange('/manage-content/image-gallery');
        this.isOptionList = false;
        break;
      case 4:
        this.routeChange('/manage-content/custom-pages');
        this.isOptionList = false;
        break;
      case 5:
        this.routeChange('/manage-content/testimonials');
        this.isOptionList = false;
        break;
    }
  }

  routeChange(route: string) {
    if (this.mlcService.fpId)
      this.router.navigate([route], {
        queryParams: { storeId: this.mlcService.fpId },
      });
    else {
      this.router.navigate([route]);
    }
  }

  postAnUpdate() {
    const dialogRef = this.dialog.open(AddUpdateComponent, {
      width: '750px',
      disableClose: true,
    });
    this.dialogSub = dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== '') {
        this.isOptionList = false;
        this.onOpenUpdatePostModal(result);
      }
    });
  }
  onOpenUpdatePostModal(message: string) {
    const dialogRef = this.dialog.open(UpdatePostedComponent, {
      width: '498px',
      backdropClass: 'popupBackdropClass',
      panelClass: 'updated-posted',
      disableClose: true,
      data: {
        // currentUpdateCount: this.latestUpdate.totalCount,
        // message,
      },
    });
  }

  onWatchVideoPopUp() {
    const dialogRef = this.dialog.open(VideoModalComponent, {
      panelClass: 'video-modal',
    });
  }

  onClickViewNotifications() {
    this.showNotificationList = false;
    this.router.navigateByUrl('/manage-content/notifications');
  }
  openDomainEmail() {
    const dialogRef = this.dialog.open(DomainAndEmailComponent, {
      disableClose: true,
    });
  }

  openRepublishWebsite() {
    const dialogRef = this.dialog.open(RepublishWebsiteModalComponent, {
      disableClose: true,
    });
  }

  openNeedHelpModal() {
    const dialogRef = this.dialog.open(NeedHelpModalComponent, {
      width: '444px',
      panelClass: 'need-help',
      backdropClass: 'dark-backdrop',
      disableClose: true,
    });
  }

  openAllStore() {
    const dialogRef = this.dialog.open(AllStoreComponent, {
      backdropClass: 'dark-backdrop',
      panelClass: 'full-width-pane',
      disableClose: true,
    });
  }
}
