import { NfAppExpCode, NfApplication } from '../menu.enum';
import { NfMenuConfig } from '../menu.type';

const menuName = 'E-COMMERCE';
export const MLC_ECOMMERCE_MENU_CONFIG: NfMenuConfig[] = [
  {
    application: NfApplication.Ecommerce,
    appExpCode: NfAppExpCode.MFG,
    menu: {
      name: menuName,
      items: [
        {
          name: 'Business Enquiries',
          url: '/enterprise/e-commerce/business-enquiries',
          meta: {
            boostTitle: 'Ardhim: Business Enquiries',
            jioTitle: 'JioOnline: Business Enquiries',
          },
        },
        {
          name: 'Business Calls',
          url: '/enterprise/e-commerce/business-calls',
          meta: {
            boostTitle: 'Ardhim: Business Calls',
            jioTitle: 'JioOnline: Business Calls',
          },
        },
        {
          name: 'Socials',
          url: '/enterprise/e-commerce/social',
          meta: {
            boostTitle: 'Ardhim: Socials',
            jioTitle: 'JioOnline: Socials',
          },
        },
      ],
    },
  },
];
