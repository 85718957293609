import { NfAppExpCode, NfApplication } from '../menu.enum';
import { NfMenuConfig } from '../menu.type';

const menuName = 'SETTINGS';
export const MLC_SETTING_MENU_CONFIG: NfMenuConfig[] = [
  {
    application: NfApplication.Setting,
    appExpCode: NfAppExpCode.MFG,
    menu: {
      name: menuName,
      items: [
        {
          name: 'Change Password',
          url: '/enterprise/setting/change-password',
          meta: {
            boostTitle: 'Ardhim: Change Password',
            jioTitle: 'JioOnline: Change Password',
          },
        },
        {
          name: 'Logout',
          url: '/enterprise/setting/logout',
        },
      ],
    },
  },
];
